<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="600px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">

                    <el-form-item label="检查项：" prop="checkerId">
                        <el-input v-model="m.item"></el-input>
                    </el-form-item>

                    <el-form-item label="验收意见：" prop="suggest">
                        <el-input v-model="m.suggest"></el-input>
                    </el-form-item>
                    <el-form-item label="问题部位：" prop="issue">
                        <el-input v-model="m.issue"></el-input>
                    </el-form-item>
                    <el-form-item label="是否合格">
                        <el-switch v-model="m.qualified" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                    <el-form-item label="图片">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false"
                            :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_img"
                            :before-remove="remove_img" :file-list="m.imgFile" list-type="picture-card">
                            <el-button size="mini" type="primary">点击上传</el-button>
                        </el-upload>

                    </el-form-item>
                    <el-form-item label="附件">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false"
                            :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_files"
                            :before-remove="remove_files" :file-list="m.filesFile">
                            <el-button size="mini" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">上传附件</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input type="textarea" rows="2" placeholder="备注" v-model="m.info"></el-input>
                    </el-form-item>
                    <el-form-item label="是否要整改" :active-value="1" :inactive-value="0">
                        <el-switch v-model="m.improve"></el-switch>
                    </el-form-item>
                    <el-form-item label="检查人" prop="issue">
                        <el-input v-model="m.checkName"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                    <el-alert title="仅支持当天修改日志内容，且不支持删除。" type="warning" center show-icon>
                        </el-alert>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
//import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    //components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                projectId: [],
                suggest: [],
                issue: [],
                qualified: [],
                img: [],
                files: [],
                info: [],
                improve: [],
                checkerId: [],
            },
            fileList: [],
        }
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改质量检查";
                data.imgFile = JSON.parse(data.img);
                data.filesFile = JSON.parse(data.files);
                this.m = data;
            } else {
                this.m = {
                    projectId: data.projectId,
                    suggest: '',
                    issue: '',
                    qualified: 0,
                    img: '',
                    files: '',
                    info: '',
                    improve: 0,
                    checkName: 0,
                    filesFile: [],
                    imgFile: []
                }
                this.title = "添加质量检查";
            }
        },
        success_img(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.imgFile) {
                this.m.imgFile = [];
            } this.m.imgFile.push(response.data);
            console.log(fileList);
        },
        remove_img(file, fileList) {
            this.m.imgFile = fileList;
        },
        success_files(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.filesFile) {
                this.m.filesFile = [];
            } this.m.filesFile.push(response.data);
            console.log(fileList);
        },
        remove_files(file, fileList) {
            this.m.filesFile = fileList;
        },

        //提交项目核查信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.m.img = JSON.stringify(this.m.imgFile.map(item => {
                        let a = {};
                        a.name = item.name;
                        a.url = item.url;
                        return a;
                    }));
                    this.m.files = JSON.stringify(this.m.filesFile.map(item => {
                        let a = {};
                        a.name = item.name;
                        a.url = item.url;
                        return a;
                    }));
                    this.m.improve = this.m.improve ? 1 : 0;
                    this.m.qualified = this.m.qualified ? 1 : 0;
                    this.sa.post("/check/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {
    },
};
</script>